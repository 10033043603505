import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_FloatingBtn = _resolveComponent("FloatingBtn")!
  const _component_ScrollToTop = _resolveComponent("ScrollToTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, { class: "app-content" }),
      _createVNode(_component_Footer),
      _createVNode(_component_FloatingBtn)
    ]),
    _createVNode(_component_ScrollToTop)
  ], 64))
}