import imgLogo from '@/assets/images/logo.png';
import imgScrollToTop from '@/assets/images/scroll-to-top.svg';

// #region Home
import imgHomeslider1 from '@/assets/images/ill-home-slider-1.gif';
import imgHomeslider2 from '@/assets/images/ill-home-slider-2.gif';
import imgHomeslider3 from '@/assets/images/ill-home-slider-3.gif';
import imgHomeslider4 from '@/assets/images/ill-home-slider-4.gif';
// #endregion Home

// #region Skills
import imgSkillsHeader from '@/assets/images/ill-home-skills-header.svg';
import imgSkillsExperience from '@/assets/images/ill-home-skills-experience.svg';
import imgSkillsCreativeTeam from '@/assets/images/ill-home-skills-creative-team.svg';
import imgSkillsHighQuality from '@/assets/images/ill-home-skills-high-quality.svg';
// #endregion Skills

// #region Services
import imgServicesDesktopApplications from '@/assets/images/ill-home-services-desktop-applications.svg';
import imgServicesMobileApplications from '@/assets/images/ill-home-services-mobile-applications.svg';
import imgServicesWebApplications from '@/assets/images/ill-home-services-web-applications.svg';
import imgServicesDesigning from '@/assets/images/ill-home-services-designing.svg';
import imgServicesSEO from '@/assets/images/ill-home-services-seo.svg';
import imgServicesEMarketing from '@/assets/images/ill-home-services-e-marketing.svg';
import imgServicesEmbeddedSystems from '@/assets/images/ill-home-services-embedded-systems.svg';
import imgServicesWebHosting from '@/assets/images/ill-home-services-web-hosting.svg';
import imgServicesTechSupport from '@/assets/images/ill-home-services-tech-support.svg';
// #endregion Services

// #region Projects
import imgProjectsHeader from '@/assets/images/ill-home-projects-header.svg';
// #endregion Projects

// #region Partners
import imgPartnersHeader from '@/assets/images/ill-home-partners-header.svg';
import imgPartnerSky from '@/assets/images/partner-sky.jpg';
import imgPartnerCooplies from '@/assets/images/partner-cooplies.jpg';
import imgPartnerSIG from '@/assets/images/partner-sig.jpg';
import imgPartnerHuawei from '@/assets/images/partner-huawei.jpg';
// #endregion Partners

// #region Clients
import imgClientsHeader from '@/assets/images/ill-home-clients-header.svg';
import imgClientNileVocationalAcademy from '@/assets/images/client-nile-vocational-academy.jpeg';
import imgClientAlmokawloonAlarab from '@/assets/images/client-almokawloon-alarab.jpeg';
import imgClientArcplanGroup from '@/assets/images/client-arcplan-group.jpeg';
import imgClientAlsharq from '@/assets/images/client-alsharq.jpeg';
import imgClientAjiad from '@/assets/images/client-ajiad.jpeg';
import imgClientPremcoPrecast from '@/assets/images/client-premco-precast.jpeg';

import imgClientLogoApache from '@/assets/images/client-logo-apache.png';
import imgClientLogoArcplanGroup from '@/assets/images/client-logo-arcplan-group.jpg';
import imgClientLogoDQuick from '@/assets/images/client-logo-d-quick.png';
import imgClientLogoRefit from '@/assets/images/client-logo-refit.png';
import imgClientLogoSky from '@/assets/images/client-logo-sky.png';
import imgClientLogoSportStar from '@/assets/images/client-logo-sport-star.png';
// #endregion Clients

// #region Employees
import imgEmployeeMohammedOkasha from '@/assets/images/employee-mohammed-okasha.jpg';
import imgEmployeeAhmedOmar from '@/assets/images/employee-ahmed-omar.jpg';
import imgEmployeeKareemReda from '@/assets/images/employee-kareem-reda.jpg';
// #endregion Employees

// #region AboutUs
import imgAboutUsHeader from '@/assets/images/ill-home-aboutus-header.svg';
// #endregion AboutUs

// #region socialMedia
import imgSocialMediaFacebook from '@/assets/images/social-media-facebook.svg';
import imgSocialMediaWhatsapp from '@/assets/images/social-media-whatsapp.svg';
import imgSocialMediaYoutube from '@/assets/images/social-media-youtube.svg';
import imgSocialMediaLinkedin from '@/assets/images/social-media-linkedin.svg';
import imgSocialMediaTwitter from '@/assets/images/social-media-twitter.svg';
// #endregion socialMedia

// #region Footer
import imgAddress from '@/assets/images/address.svg';
import imgPhone from '@/assets/images/phone.svg';
import imgMail from '@/assets/images/mail.svg';
// #endregion Footer

// #region servicesPage
import imgEducational from '@/assets/images/educational.svg';
import imgSports from '@/assets/images/sports.svg';
import imgMedical from '@/assets/images/medical.svg';
import imgHR from '@/assets/images/hr.svg';
import imgPOS from '@/assets/images/pos.svg';
// #region Educational
import imgInternationalAcademy from '@/assets/images/InternationalAcademy.png';
import imgBritishInternationalCollegeOfCairo from '@/assets/images/britishInternationalCollegeOfCairo.png';
import imgFutureAcademy from '@/assets/images/futureAcademy.png';
import imgIdress from '@/assets/images/idress.jpeg';
import imgTohfaEducationalGate from '@/assets/images/tohfaEducationalGate.jpeg';
import imgInCenter from '@/assets/images/inCenter.png';
import imgVCA from '@/assets/images/VCA.png';
import imgLePetitSavant from '@/assets/images/LePetitSavant.jpeg';
import imgForumForVocationalTraining from '@/assets/images/ForumForVocationalTraining.jpg';
import imgEgyptAcademy from '@/assets/images/EgyptAcademy.jpg';
import imgworkplace from '@/assets/images/workplace.jpg';
import imgPhoneApps from '@/assets/images/phone.jpg';
import imgWeb from '@/assets/images/web.jpg';
import imgDesign from '@/assets/images/design.jpg';
import imgSEO from '@/assets/images/seo.png';
import imgDigitalMarketing from '@/assets/images/digitalMarketing.jpg';
import imgEmbedded from '@/assets/images/embedded.jpg';
import imgHosting from '@/assets/images/hosting.jpg';
import imgCustomerService from '@/assets/images/customerService.png';
import imgNileLogo from '@/assets/images/nileLogo.png';
import imgArcplanLogo from '@/assets/images/logo_arcplan.jpg';
import imgArabContrectorsFCLogo from '@/assets/images/ArabContrectorsFC.png';
import imgAlphaAcademy from '@/assets/images/educational/AlphaAcademy.png';
import imgElixirAwadShalapy from '@/assets/images/educational/ElixirAwadShalapy.png';
import imgElmohands from '@/assets/images/educational/Elmohands.png';
import imgElsaidInMath from '@/assets/images/educational/ElsaidInMath.png';
import imgElwadeh from '@/assets/images/educational/Elwadeh.png';
import imgGoSmartAmericanAcademy from '@/assets/images/educational/GoSmartAmericanAcademy.png';
import imgMath4u from '@/assets/images/educational/Math4u.png';
import imgMenofiaCoursesAcademy from '@/assets/images/educational/MenofiaCoursesAcademy.png';
import imgModernOnlineSchool from '@/assets/images/educational/ModernOnlineSchool.png';
import imgTeachMe from '@/assets/images/educational/TeachMe.png';
// #endregion Educational

// #region Sports
import imgBayernAcademy from '@/assets/images/sports/BayernAcademy.jpeg';
import imgBetaAcademy from '@/assets/images/sports/BetaAcademy.png';
import imgBlueSky from '@/assets/images/sports/BlueSky.jpeg';
import imgDolvinusAcademy from '@/assets/images/sports/DolvinusAcademy.png';
import imgDoubleDotSquashAcademy from '@/assets/images/sports/DoubleDotSquashAcademy.png';
import imgSwimmer from '@/assets/images/sports/Swimmer.png';
import imgGoldenSwimming from '@/assets/images/sports/GoldenSwimming.png';
import imgKoraMasr from '@/assets/images/sports/KoraMasr.jpeg';
import imgLionsAcademy from '@/assets/images/sports/LionsAcademy.png';
import imgMahrosa from '@/assets/images/sports/Mahrosa.jpeg';
import imgPerfrctSportingAcademy from '@/assets/images/sports/PerfrctSportingAcademy.jpeg';
import imgPowerTeam from '@/assets/images/sports/PowerTeam.png';
import imgRefit from '@/assets/images/sports/Refit.jpeg';
import imgSantos from '@/assets/images/sports/Santos.jpeg';
import imgShadowPaddel from '@/assets/images/sports/ShadowPaddel.jpeg';
import imgSherwqAcademy from '@/assets/images/sports/SherwqAcademy.jpeg';
import imgSportStar from '@/assets/images/sports/SportStar.jpeg';
import imgSwimClub from '@/assets/images/sports/SwimClub.jpeg';
// #endregion Sports

// #region Medical
import imgAjiad from '@/assets/images/medical/Ajiad.png';
import imgAGBiology from '@/assets/images/medical/AGBiology.png';
import imgAldalal from '@/assets/images/medical/Aldalal.png';
import imgAlElizehClinics from '@/assets/images/medical/AlElizehClinics.png';
import imgAlRajhi from '@/assets/images/medical/AlRajhi.png';
import imgArttop from '@/assets/images/medical/Arttop.png';
import imgDeiQuik from '@/assets/images/medical/DeiQuik.png';
import imgElgendy from '@/assets/images/medical/Elgendy.png';
import imgPanoramaRedaHelmy from '@/assets/images/medical/PanoramaRedaHelmy.png';
import imgTatbib from '@/assets/images/medical/Tatbib.png';
import imgWelcareClinics from '@/assets/images/medical/WelcareClinics.png';
import imgWellnessClinics from '@/assets/images/medical/WellnessClinics.png';
import imgZamzamClinics from '@/assets/images/medical/ZamzamClinics.png';
// #endregion Medical

// #region HR
import imgPremco from '@/assets/images/hr/Premco.png';
import imgBioStream from '@/assets/images/hr/BioStream.png';
import imgJbsDenim from '@/assets/images/hr/JbsDenim.png';
import imgSamanaSwtak from '@/assets/images/hr/SamanaSwtak.png';
// #endregion HR

// #region POS
import imgAlsharq from '@/assets/images/pos/Alsharq.png';
import imgCollection from '@/assets/images/pos/Collection.png';
// #endregion POS

// #region custom
import imgSaveCard from '@/assets/images/custom/SaveCard.png';
import imgHomSlider1 from '@/assets/images/homSlider1.png';
import imgHomSlider2 from '@/assets/images/homSlider2.png';
import imgHomSlider3 from '@/assets/images/homSlider3.png';
import imgHomSlider4 from '@/assets/images/homSlider4.png';
import imgHomSlider5 from '@/assets/images/HomSlider5.png';
import imgHomSlider6 from '@/assets/images/HomSlider6.png';
import imgHomSlider7 from '@/assets/images/HomSlider7.png';
import imgHomSlider8 from '@/assets/images/HomSlider8.png';
import imgHomSlider9 from '@/assets/images/HomSlider9.png';
import imgHomSlider10 from '@/assets/images/HomSlider10.png';
// #endregion custom

// #endregion servicesPage

// #region aboutPage
import imgThinkingOutOfBox from '@/assets/images/thinkingOutOfBox.png';
import imgAboutS1 from '@/assets/images/AboutS1.png';
import imgAboutS2 from '@/assets/images/AboutS2.png';
import imgAboutS3 from '@/assets/images/AboutS3.png';
import imgAboutS4 from '@/assets/images/AboutS4.png';
import imgMision from '@/assets/images/Mision.png';
import imgVision from '@/assets/images/Vision.png';
import imgValue from '@/assets/images/Value.png';
// #endregion aboutPage
import imgHotline from '@/assets/images/hotline.svg';

export {
  imgLogo,
  imgScrollToTop,
  imgHomeslider1,
  imgHomeslider2,
  imgHomeslider3,
  imgHomeslider4,
  imgSkillsHeader,
  imgSkillsExperience,
  imgSkillsCreativeTeam,
  imgSkillsHighQuality,
  imgServicesDesktopApplications,
  imgServicesMobileApplications,
  imgServicesWebApplications,
  imgServicesDesigning,
  imgServicesSEO,
  imgServicesEMarketing,
  imgServicesEmbeddedSystems,
  imgServicesWebHosting,
  imgServicesTechSupport,
  imgProjectsHeader,
  imgPartnersHeader,
  imgPartnerSky,
  imgPartnerCooplies,
  imgPartnerSIG,
  imgPartnerHuawei,
  imgClientsHeader,
  imgClientNileVocationalAcademy,
  imgClientAlmokawloonAlarab,
  imgClientArcplanGroup,
  imgClientAlsharq,
  imgClientAjiad,
  imgClientPremcoPrecast,
  imgClientLogoApache,
  imgClientLogoArcplanGroup,
  imgClientLogoDQuick,
  imgClientLogoRefit,
  imgClientLogoSky,
  imgClientLogoSportStar,
  imgEmployeeMohammedOkasha,
  imgEmployeeAhmedOmar,
  imgEmployeeKareemReda,
  imgAboutUsHeader,
  imgSocialMediaFacebook,
  imgSocialMediaWhatsapp,
  imgSocialMediaYoutube,
  imgSocialMediaLinkedin,
  imgSocialMediaTwitter,
  imgAddress,
  imgPhone,
  imgMail,
  imgEducational,
  imgSports,
  imgMedical,
  imgHR,
  imgPOS,

  // #region educational CAT1
  imgInternationalAcademy,
  imgBritishInternationalCollegeOfCairo,
  imgFutureAcademy,
  imgIdress,
  imgTohfaEducationalGate,
  imgInCenter,
  imgVCA,
  imgLePetitSavant,
  imgForumForVocationalTraining,
  imgEgyptAcademy,
  imgworkplace,
  imgPhoneApps,
  imgWeb,
  imgDesign,
  imgSEO,
  imgDigitalMarketing,
  imgEmbedded,
  imgHosting,
  imgCustomerService,
  imgNileLogo,
  imgArcplanLogo,
  imgArabContrectorsFCLogo,
  imgAlphaAcademy,
  imgElixirAwadShalapy,
  imgElmohands,
  imgElsaidInMath,
  imgElwadeh,
  imgGoSmartAmericanAcademy,
  imgMath4u,
  imgMenofiaCoursesAcademy,
  imgModernOnlineSchool,
  imgTeachMe,
  // #endregion educational CAT1

  // #region Sports CAT2
  imgBayernAcademy,
  imgBetaAcademy,
  imgBlueSky,
  imgDolvinusAcademy,
  imgDoubleDotSquashAcademy,
  imgSwimmer,
  imgGoldenSwimming,
  imgKoraMasr,
  imgLionsAcademy,
  imgMahrosa,
  imgPerfrctSportingAcademy,
  imgPowerTeam,
  imgRefit,
  imgSantos,
  imgShadowPaddel,
  imgSherwqAcademy,
  imgSportStar,
  imgSwimClub,
  // #endregion Sports CAT2

  // #region Medical CAT3
  imgAjiad,
  imgAGBiology,
  imgAldalal,
  imgAlElizehClinics,
  imgAlRajhi,
  imgArttop,
  imgDeiQuik,
  imgElgendy,
  imgPanoramaRedaHelmy,
  imgTatbib,
  imgWelcareClinics,
  imgWellnessClinics,
  imgZamzamClinics,
  // #endregion Medical CAT3

  // #region HR CAT4
  imgPremco,
  imgBioStream,
  imgJbsDenim,
  imgSamanaSwtak,
  // #endregion HR CAT4

  // #region POS CAT5
  imgAlsharq,
  imgCollection,
  // #endregion POS CAT5

  // #region Custom CAT6
  imgSaveCard,
  imgHomSlider1,
  imgHomSlider2,
  imgHomSlider3,
  imgHomSlider4,
  imgHomSlider5,
  imgHomSlider6,
  imgHomSlider7,
  imgHomSlider8,
  imgHomSlider9,
  imgHomSlider10,
  // #endregion Custom CAT6
  imgThinkingOutOfBox,
  imgAboutS1,
  imgAboutS2,
  imgAboutS3,
  imgAboutS4,
  imgMision,
  imgVision,
  imgValue,
  imgHotline,
};
