
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import CustomCard from './../../../types/CustomCard';
import SectionHeader from './../../../components/SectionHeader.vue';
import CustomCards from './../../../components/CustomCards.vue';
import {
  imgClientsHeader,
  imgClientLogoSky,
  imgArabContrectorsFCLogo,
  imgClientLogoDQuick,
  imgClientLogoArcplanGroup,
  imgClientLogoRefit,
  imgBritishInternationalCollegeOfCairo,
} from './../../../utils/images';

export default defineComponent({
  components: { SectionHeader, CustomCards },
  setup() {
    const { t } = useI18n();
    const CustomCardsList: CustomCard[] = [
      {
        title: t('home.clients.cards.title1'),
        description: t('home.clients.cards.description1'),
        image: imgClientLogoSky,
      },
      {
        title: t('home.projects.cards.title2'),
        description: t('home.clients.cards.description2'),
        image: imgArabContrectorsFCLogo,
      },
      {
        title: t('home.clients.cards.title3'),
        description: t('home.clients.cards.description3'),
        image: imgClientLogoDQuick,
      },
      {
        title: t('home.clients.cards.title4'),
        description: t('home.clients.cards.description4'),
        image: imgClientLogoArcplanGroup,
      },
      {
        title: t('home.clients.cards.title5'),
        description: t('home.clients.cards.description5'),
        image: imgClientLogoRefit,
      },
      {
        title: t('servicesPage.projects.categories.cat1.cards.title4'),
        description: t('home.clients.cards.description6'),
        image: imgBritishInternationalCollegeOfCairo,
      },
    ];

    return {
      t,
      imgClientsHeader,
      CustomCardsList,
    };
  },
});
