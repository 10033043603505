import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "btn-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_CustomCards = _resolveComponent("CustomCards")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.clients.header.title'),
      description: _ctx.t('home.clients.header.description'),
      image: _ctx.imgClientsHeader,
      txtPadding: "5rem",
      scale: "0.5",
      height: "400px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CustomCards, { list: _ctx.CustomCardsList }, null, 8, ["list"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_b_button, {
        pill: "",
        variant: "outline-success"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, { to: { name: 'Clients' } }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('navbar.ClientsAndPartners')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}