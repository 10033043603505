
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    height: { type: String, default: 'auto' },
    width: { type: String, default: 'auto' },
    imgWidth: { type: String, default: '40%' },
    imgHeight: { type: String, default: 'auto' },
    txtWidth: { type: String, default: '60%' },
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    image: { type: String, default: '' },
    txtPadding: { type: String, default: '1rem' },
    scale: { type: String, default: '1' },
  },
});
