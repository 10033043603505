import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!
  const _component_Skills = _resolveComponent("Skills")!
  const _component_Services = _resolveComponent("Services")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_Clients = _resolveComponent("Clients")!
  const _component_Partners = _resolveComponent("Partners")!
  const _component_AboutUs = _resolveComponent("AboutUs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Slider),
    _createVNode(_component_Skills),
    _createVNode(_component_Services),
    _createVNode(_component_Projects),
    _createVNode(_component_Clients),
    _createVNode(_component_Partners),
    _createVNode(_component_AboutUs)
  ]))
}