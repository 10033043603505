
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    name: { type: String, default: '' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    image: { type: String, default: '' },
  },
});
