import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_CustomCards = _resolveComponent("CustomCards")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SectionTitle, {
      title: _ctx.t('home.services.header.title')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CustomCards, { list: _ctx.CustomCardsList }, null, 8, ["list"])
    ])
  ]))
}