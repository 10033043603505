import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db3703f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-title" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomDivider = _resolveComponent("CustomDivider")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_CustomDivider)
  ]))
}