
import { defineComponent, PropType } from 'vue';
import CustomCard from './../types/CustomCard';
import { ITEMS_WIDTH } from './../utils/constants';

export default defineComponent({
  props: {
    list: { type: Array as PropType<CustomCard[]> },
    itemWidth: { type: String, default: ITEMS_WIDTH.third },
    itemWidthTablet: { type: String, default: ITEMS_WIDTH.half },
    itemWidthMobile: { type: String, default: ITEMS_WIDTH.full },
    imgWidth: { type: String, default: '200px' },
    imgHeight: { type: String, default: '200px' },
    displayTitle: { type: String, default: 'block' },
  },
});
