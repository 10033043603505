import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_CustomCards = _resolveComponent("CustomCards")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.partners.header.title'),
      description: _ctx.t('home.partners.header.description'),
      image: _ctx.imgPartnersHeader,
      txtPadding: "5rem",
      scale: "0.5",
      height: "400px"
    }, null, 8, ["title", "description", "image"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CustomCards, {
        list: _ctx.CustomCardsList,
        imgWidth: "100%",
        imgHeight: "150px",
        itemWidth: _ctx.ITEMS_WIDTH.quarter,
        itemWidthTablet: _ctx.ITEMS_WIDTH.half
      }, null, 8, ["list", "itemWidth", "itemWidthTablet"])
    ])
  ]))
}