import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58b5e8be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "floating-contacts" }
const _hoisted_2 = { class: "center" }
const _hoisted_3 = { class: "share-menu" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "social-btn" }
const _hoisted_6 = ["title"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "social-btn" }
const _hoisted_9 = ["title"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "social-btn" }
const _hoisted_12 = ["title"]
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "social-btn" }
const _hoisted_15 = ["title"]
const _hoisted_16 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "share-btn",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleShareMenu()))
        }, [
          _createElementVNode("img", { src: _ctx.imgHotline }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            href: "https://api.whatsapp.com/send?phone=201208080847",
            target: "_blank",
            title: _ctx.t('footer.socialMedia.whatsApp')
          }, [
            _createElementVNode("img", { src: _ctx.imgSocialMediaWhatsapp }, null, 8, _hoisted_7)
          ], 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("a", {
            href: "https://www.facebook.com/AdminsSystems",
            target: "_blank",
            title: _ctx.t('footer.socialMedia.faceBook')
          }, [
            _createElementVNode("img", { src: _ctx.imgSocialMediaFacebook }, null, 8, _hoisted_10)
          ], 8, _hoisted_9)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("a", {
            href: "https://www.youtube.com/channel/UCxeh_kVUtUCzu2lxgo30vWA",
            target: "_blank",
            title: _ctx.t('footer.socialMedia.youTube')
          }, [
            _createElementVNode("img", { src: _ctx.imgSocialMediaYoutube }, null, 8, _hoisted_13)
          ], 8, _hoisted_12)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("a", {
            href: "https://twitter.com/admiinsegypt?t=ZqlsOnTwtP7PLum36pl_0Q&s=09",
            target: "_blank",
            title: _ctx.t('footer.socialMedia.twitter')
          }, [
            _createElementVNode("img", { src: _ctx.imgSocialMediaTwitter }, null, 8, _hoisted_16)
          ], 8, _hoisted_15)
        ])
      ])
    ])
  ]))
}