import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d77633f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-to-top" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "scroll-btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToTop()))
    }, [
      _createElementVNode("img", { src: _ctx.imgScrollToTop }, null, 8, _hoisted_2)
    ])
  ]))
}