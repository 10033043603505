import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c75018"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-card" }
const _hoisted_2 = {
  key: 0,
  class: "image-container"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "title"
}
const _hoisted_5 = {
  key: 2,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "custom-cards",
    style: _normalizeStyle(`
      --item-width: ${_ctx.itemWidth};
      --item-width-tablet: ${_ctx.itemWidthTablet};
      --item-width-mobile: ${_ctx.itemWidthMobile};
      --img-width: ${_ctx.imgWidth};
      --img-height: ${_ctx.imgHeight};
      --display-title: ${_ctx.displayTitle};
    `)
  }, [
    (_ctx.list)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.list, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "custom-card animated-item"
          }, [
            _createElementVNode("div", _hoisted_1, [
              (item.image)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("img", {
                      src: item.image
                    }, null, 8, _hoisted_3)
                  ]))
                : _createCommentVNode("", true),
              (item.title)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(item.title), 1))
                : _createCommentVNode("", true),
              (item.description)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(item.description), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      : _createCommentVNode("", true)
  ], 4))
}