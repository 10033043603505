import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f147c8d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", {
    class: "section-header animated-item",
    style: _normalizeStyle(`
      --height: ${_ctx.height};
      --width: ${_ctx.width};
    `)
  }, [
    _createElementVNode("div", {
      class: "text",
      style: _normalizeStyle(`
      --txt-width: ${_ctx.txtWidth};
      --txt-padding: ${_ctx.txtPadding};
    `)
    }, [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.description), 1)
    ], 4),
    _createElementVNode("div", {
      class: "image-container",
      style: _normalizeStyle(`
      --img-width: ${_ctx.imgWidth};
      --img-height: ${_ctx.imgHeight};
      --scale: ${_ctx.scale};
    `)
    }, [
      _createElementVNode("img", { src: _ctx.image }, null, 8, _hoisted_3)
    ], 4)
  ], 4))
}