
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Slide from './../../../types/Slide';
import Slider from './../../../components/Slider.vue';
import {
  imgHomSlider1,
  imgHomSlider2,
  imgHomSlider3,
  imgHomSlider4,
  imgHomSlider5,
  imgHomSlider6,
  imgHomSlider7,
  imgHomSlider8,
  imgHomSlider9,
  imgHomSlider10,
} from './../../../utils/images';

export default defineComponent({
  components: { Slider },
  setup() {
    const { t } = useI18n();
    const list: Slide[] = [
      {
        activeStatus: true,
        title: t('home.slider.description1'),
        description: '',
        image: imgHomSlider10,
      },
      {
        activeStatus: false,
        title: t('home.slider.title1'),
        description: '',
        image: imgHomSlider7,
      },
      {
        activeStatus: false,
        title: t('home.slider.description2'),
        description: '',
        image: imgHomSlider5,
      },
      {
        activeStatus: false,
        title: t('home.slider.title1'),
        description: '',
        image: imgHomSlider8,
      },
      {
        activeStatus: false,
        title: t('home.slider.description3'),
        description: '',
        image: imgHomSlider6,
      },
      {
        activeStatus: false,
        title: t('home.slider.description5'),
        description: '',
        image: imgHomSlider4,
      },
      {
        activeStatus: false,
        title: t('home.slider.description4'),
        description: '',
        image: imgHomSlider3,
      },
      {
        activeStatus: false,
        title: t('home.slider.description7'),
        description: '',
        image: imgHomSlider2,
      },
      {
        activeStatus: false,
        title: t('home.slider.description6'),
        description: '',
        image: imgHomSlider9,
      },
      {
        activeStatus: false,
        title: t('home.slider.title1'),
        description: '',
        image: imgHomSlider1,
      },
    ];

    return { list };
  },
});
