
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { LANGUAGES, THEMES } from './../utils/constants';
import {
  getAppLanguage,
  setAppLanguage,
  getAppTheme,
  setAppTheme,
  shrinkNavbar,
} from './../utils/helper';
import { imgLogo } from './../utils/images';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const language = getAppLanguage();
    const theme = getAppTheme();
    shrinkNavbar();

    return {
      t,
      LANGUAGES,
      language,
      THEMES,
      theme,
      setAppLanguage,
      setAppTheme,
      imgLogo,
    };
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    clicked() {
      this.checked = false;
    },
  },
});
