
import { useI18n } from 'vue-i18n';
import { defineComponent } from 'vue';
import {
  imgHotline,
  imgSocialMediaWhatsapp,
  imgSocialMediaFacebook,
  imgSocialMediaYoutube,
  imgSocialMediaTwitter,
} from './../utils/images';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
      imgHotline,
      imgSocialMediaWhatsapp,
      imgSocialMediaFacebook,
      imgSocialMediaYoutube,
      imgSocialMediaTwitter,
    };
  },
  created() {
    // this.toggleShareMenu();
  },
  methods: {
    toggleShareMenu() {
      document
        .getElementsByClassName('share-menu')[0]
        .classList.toggle('active');
    },
  },
});
