
import { defineComponent } from 'vue';
import { showOrHideScrollBtn, scrollToTop } from './../utils/helper';
import { imgScrollToTop } from './../utils/images';

export default defineComponent({
  setup() {
    showOrHideScrollBtn();

    return { scrollToTop, imgScrollToTop };
  },
});
