
import { defineComponent, PropType } from 'vue';
import StatisticsItem from './../types/StatisticsItem';
import { ITEMS_WIDTH } from './../utils/constants';

export default defineComponent({
  props: {
    list: { type: Array as PropType<StatisticsItem[]> },
    itemWidth: { type: String, default: ITEMS_WIDTH.quarter },
    itemWidthTablet: { type: String, default: ITEMS_WIDTH.third },
    itemWidthMobile: { type: String, default: ITEMS_WIDTH.half },
  },
});
