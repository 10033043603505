
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { ITEMS_WIDTH } from './../../../utils/constants';
import CustomCard from './../../../types/CustomCard';
import SectionHeader from './../../../components/SectionHeader.vue';
import CustomCards from './../../../components/CustomCards.vue';
import {
  imgPartnersHeader,
  imgPartnerSky,
  imgPartnerCooplies,
  imgPartnerSIG,
  imgPartnerHuawei,
} from './../../../utils/images';

export default defineComponent({
  components: { SectionHeader, CustomCards },
  setup() {
    const { t } = useI18n();
    const CustomCardsList: CustomCard[] = [
      {
        title: '',
        description: '',
        image: imgPartnerSky,
      },
      {
        title: '',
        description: '',
        image: imgPartnerCooplies,
      },
      {
        title: '',
        description: '',
        image: imgPartnerSIG,
      },
      {
        title: '',
        description: '',
        image: imgPartnerHuawei,
      },
    ];

    return { t, ITEMS_WIDTH, imgPartnersHeader, CustomCardsList };
  },
});
