import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/home/Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About/About.vue'),
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('../views/Clients/Clients.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services/Services.vue'),
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('../views/ContactUs/ContactUs.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
