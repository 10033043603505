import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "general-statistics animated-item",
    style: _normalizeStyle(`
      --item-width: ${_ctx.itemWidth};
      --item-width-tablet: ${_ctx.itemWidthTablet};
      --item-width-mobile: ${_ctx.itemWidthMobile};
    `)
  }, [
    (_ctx.list)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.list, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "statistics-item"
          }, [
            _createElementVNode("h1", null, _toDisplayString(item.number), 1),
            _createElementVNode("h3", null, _toDisplayString(item.text), 1)
          ]))
        }), 128))
      : _createCommentVNode("", true)
  ], 4))
}