
import { defineComponent } from 'vue';
import CustomDivider from './CustomDivider.vue';

export default defineComponent({
  components: { CustomDivider },
  props: {
    title: { type: String, default: '' },
  },
});
