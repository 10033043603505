
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import SocialMedia from './../types/SocialMedia';
import ContactData from './../types/ContactData';
import SectionTitle from './SectionTitle.vue';
import CustomDivider from './CustomDivider.vue';
import {
  imgLogo,
  imgAddress,
  imgPhone,
  imgMail,
  imgSocialMediaFacebook,
  imgSocialMediaWhatsapp,
  imgSocialMediaYoutube,
  imgSocialMediaLinkedin,
  imgSocialMediaTwitter,
} from './../utils/images';

export default defineComponent({
  components: { SectionTitle, CustomDivider },
  setup() {
    const { t } = useI18n();
    const contactDataList: ContactData[] = [
      {
        title: t('footer.contactData.address'),
        list: [t('footer.contactData.address1')],
        image: imgAddress,
        phoneNumber: false,
      },
      {
        title: t('footer.contactData.phone'),
        list: [t('footer.contactData.phone1')],
        image: imgPhone,
        phoneNumber: true,
      },
      {
        title: t('footer.contactData.mail'),
        list: [t('footer.contactData.mail1')],
        image: imgMail,
        phoneNumber: false,
      },
    ];
    const socialMediaList: SocialMedia[] = [
      {
        title: t('footer.socialMedia.faceBook'),
        link: 'https://www.facebook.com/AdminsSystems',
        image: imgSocialMediaFacebook,
      },
      {
        title: t('footer.socialMedia.whatsApp'),
        link: 'https://api.whatsapp.com/send?phone=201208080847',
        image: imgSocialMediaWhatsapp,
      },
      {
        title: t('footer.socialMedia.youTube'),
        link: 'https://www.youtube.com/channel/UCxeh_kVUtUCzu2lxgo30vWA',
        image: imgSocialMediaYoutube,
      },
      {
        title: t('footer.socialMedia.linkedIn'),
        link: 'https://www.linkedin.com/company/admins-egypt/',
        image: imgSocialMediaLinkedin,
      },
      {
        title: t('footer.socialMedia.twitter'),
        link: 'https://twitter.com/admiinsegypt?t=ZqlsOnTwtP7PLum36pl_0Q&s=09',
        image: imgSocialMediaTwitter,
      },
    ];

    return { t, imgLogo, contactDataList, socialMediaList };
  },
});
