
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import ScrollToTop from './components/ScrollToTop.vue';
import FloatingBtn from './components/FloatingBtn.vue';
import {
  getAppLanguage,
  setAppTitle,
  setRootStyleLang,
  setRootStyleTheme,
} from './utils/helper';

export default defineComponent({
  components: { Navbar, Footer, ScrollToTop, FloatingBtn },
  mounted() {
    // let animatedItems =
    //   (document.querySelectorAll(
    //     '.animated-item',
    //     // eslint-disable-next-line no-undef
    //   ) as NodeListOf<Element>) || [];
    //   window.addEventListener('scroll', () => {
    //     animatedItems.forEach((animatedItem: Element) => {
    //       const sectionTop = animatedItem.getBoundingClientRect().top;
    //       const sectionBottom = animatedItem.getBoundingClientRect().bottom;
    //       if (
    //         window.innerHeight + window.scrollY >=
    //         document.body.offsetHeight - 1
    //       ) {
    //         // you're at the bottom of the page
    //         console.log('endOfPage');
    //         animatedItem.classList.remove('animate__animated');
    //         animatedItem.classList.remove('animate__fadeInUp');
    //       }
    //       if (sectionTop < window.innerHeight && sectionBottom >= 0) {
    //         animatedItem.classList.add('animate__animated');
    //         animatedItem.classList.add('animate__fadeInUp');
    //       }
    //     });
    //   });
  },

  setup() {
    const { locale } = useI18n({ useScope: 'global' });
    locale.value = getAppLanguage();
    setAppTitle();
    setRootStyleLang();
    setRootStyleTheme();
  },
});
