
import { defineComponent, PropType } from 'vue';
import Slide from './../types/Slide';

export default defineComponent({
  props: {
    id: { type: String, default: 'carouselExampleCaptions' },
    list: { type: Array as PropType<Slide[]> },
  },
});
