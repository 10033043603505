import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_GeneralStatistics = _resolveComponent("GeneralStatistics")!
  const _component_CustomCards = _resolveComponent("CustomCards")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.t('home.skills.header.title'),
      description: _ctx.t('home.skills.header.description'),
      image: _ctx.imgSkillsHeader,
      txtPadding: "5rem",
      scale: "0.5",
      height: "400px"
    }, null, 8, ["title", "description", "image"]),
    _createVNode(_component_GeneralStatistics, { list: _ctx.GeneralStatisticsList }, null, 8, ["list"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CustomCards, { list: _ctx.CustomCardsList }, null, 8, ["list"])
    ])
  ]))
}